.practice-left-side-content {
  width: 75%;
  padding-right: 25px;
  padding-bottom: 25px;
}

.practice-right-side-content {
  width: 25%;
  height: calc(100vh - 75px);
  overflow-y: scroll;
}

.note-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.note-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.note-header .title {
  font-family: Outfit;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c4250;
}

.note-header .sub-title {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #595f6e;
  padding-top: 5px;
}

.note-header .text {
  display: flex;
  flex-direction: column;
}

.note-header .action {
  display: flex;
  align-items: center;
}

.note-header .action span {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  color: #2db2c4;
  padding-right: 4px;
}

.note-box {
  margin-top: 15px;
  border-radius: 2px 7px 7px 2px;
  border: 1px solid #c5d9dc;
  background: #fff;
  display: flex;
}

.note-box-line {
  border-radius: 2px 1px 1px 2px;
  opacity: 0.6000000238418579;
  background: #35c69d;
  width: 5px;
}

.note-box-body {
  width: 100%;
}

.note-box-header-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.note-box-header-title {
  color: #3c4250;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
}

.note-body {
  padding-top: 18px;
}

.note-error-message {
  font-family: Outfit;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #595f6e;
}

.note-card {
  width: 100%;
  margin-bottom: 18px;
  display: flex;
}

.note-box-line {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  opacity: 0.6000000238418579;
  width: 5px;
}

.note-content {
  border: 1px solid #c5d9dc;
  width: 100%;
  /* height: 130px; */
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.note-content .title {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.note-content .courde-name {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c4250;
  width: 70%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.note-content .topic-box {
  display: flex;
  align-items: center;
}

.note-content .topic-wrapper {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
}

.note-content .topic-box .title {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #595f6e;
}

.note-content .topic-box .value {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c4250;
  padding-left: 4px;
}

.note-body .note-disabled-card,
.bg-course .course-disabled-card {
  background: #c8f0ec80 !important;
}

.bg-course {
  background: #c8f0ec80;
  border-radius: 10px;
}

.bg-course .course-disabled-card {
  opacity: 0.4;
  filter: blur(10px);
  -webkit-filter: blur(10px);
}

.note-body .note-disabled-card .note-box-line {
  opacity: 0.3;
  filter: blur(6px);
  -webkit-filter: blur(6px);
}

.note-body .note-disabled-card .note-content {
  opacity: 0.2;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.note-content .note-disabled-img {
  width: 45px;
  height: 45px;
}

.bg-note,
.bg-course {
  position: relative;
}

.bg-note-content,
.bg-course-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bg-note-content img,
.bg-course-content img {
  margin-bottom: 6px;
}

.course-card {
  background: #f6fffe;
  border-radius: 10px;
  border: 1px solid #a4c3c8;
}

.course-header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.course-header .course-header-box {
  border-radius: 10px 0px 10px 0px;
  padding: 4px 10px 4px 10px;
  background: #118ede1a;
  color: #118ede;
}

.course-header .active-course-header-box {
  border-radius: 10px 0px 10px 0px;
  padding: 4px 10px 4px 10px;
  background: #118ede;
  color: #ffffff;
}

.course-header span {
  font-family: Outfit;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.course-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.course-body img {
  width: 100%;
  padding: 0px 20px;
}

.course-body .title {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c4250;
  margin: 10px 0px;
}

.course-progress {
  display: flex;
}

.course-progress-text {
  display: flex;
  align-items: center;
}

.course-progress-text .MuiLinearProgress-root {
  width: 100%;
}

.course-progress-text .percentage {
  font-family: Outfit;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: right;
  color: #595f6e;
  padding-left: 10px;
}

.course-progress .course-result {
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #a4c3c8;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.course-progress .course-result span {
  width: 18px;
  font-family: Outfit;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #3c4250;
}

.course-progress .course-result .completed {
  text-align: start;
}

.course-progress .course-result .total {
  text-align: end;
}

.course-progress-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
}

.course-progress-bar span {
  font-family: Outfit;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #595f6e;
  padding-bottom: 6px;
}

.course-footer {
  margin: 10px;
}

.course-footer button {
  font-family: Outfit;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  height: 34px;
}

.course-footer .btn-completed {
  background: #35c69d1a;
  color: #35c69d;
}

.course-footer .btn-start {
  background: #35c69d;
  color: #ffffff;
}

.course-tags {
  border-radius: 30px;
  background: #e0f6f4;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 6px;
  width: fit-content;
}

.course-tags div {
  border-radius: 25px;
  width: fit-content;
  background-color: #ffffff;
  height: 100%;
  padding: 8px 14px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.course-tags div span {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #595f6e;
}

.course-tags .active-tag {
  background: linear-gradient(273.24deg, #40c2d4 0%, #3dcee8 100%);
}

.course-tags .active-tag span {
  color: #ffffff;
}

.react-tabs__tab--selected {
  color: #40c4d7;
  border-bottom: 2px solid #40c4d7;
}

@media (min-width: 1100px) and (max-width: 1279px) {
  .css-1equabv-MuiGrid-root {
    -webkit-flex-basis: 33.333333% !important;
    -ms-flex-preferred-size: 33.333333% !important;
    flex-basis: 33.333333% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333% !important;
  }
}

@media (min-width: 1024px) and (max-width: 1099px) {
  .css-1equabv-MuiGrid-root {
    -webkit-flex-basis: 50% !important;
    -ms-flex-preferred-size: 50% !important;
    flex-basis: 50% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50% !important;
  }
}

@media (min-width: 820px) and (max-width: 1023px) {
  .css-1equabv-MuiGrid-root {
    -webkit-flex-basis: 33.333333% !important;
    -ms-flex-preferred-size: 33.333333% !important;
    flex-basis: 33.333333% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333% !important;
  }
}

@media only screen and (max-width: 480px) {
  .student-content {
    width: 100%;
    flex-direction: column;
  }

  .practice-left-side-content {
    width: 100%;
    padding: 0px;
  }

  .practice-right-side-content {
    width: 100%;
    padding-top: 30px;
    height: auto;
  }

  .course-tags {
    width: 100%;
    height: fit-content;
    width: max-content;
  }

  .course-tags div {
    height: 40px;
    width: 90%;
  }

  .student-content .practice-left-side-content .MuiGrid-container {
    flex-direction: column !important;
  }

  .student-content .MuiGrid-container .MuiGrid-root {
    max-width: 100%;
  }

  .note-wrapper .note-body {
    display: flex;
    overflow: auto;
    flex-wrap: nowrap !important;
  }

  .note-wrapper .note-body .note-card,
  .note-wrapper .note-body .bg-note {
    width: 75%;
    max-width: 75%;
    min-width: 75%;
    margin-right: 15px;
  }

  .note-wrapper .note-body .bg-note .note-card {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    margin-right: 0px !important;
  }

  .course-body img {
    height: 100px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 820px) {
  .student-content {
    width: 100%;
    flex-direction: column;
  }

  .practice-left-side-content {
    width: 100%;
    padding: 0px;
  }

  .practice-right-side-content {
    width: 100%;
    padding-top: 30px;
    height: auto;
  }

  .student-content .MuiGrid-container .MuiGrid-root {
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }

  .note-wrapper .note-body {
    display: flex;
    overflow: auto;
    flex-wrap: nowrap !important;
  }

  .note-wrapper .note-body .note-card,
  .note-wrapper .note-body .bg-note {
    width: 40%;
    max-width: 40%;
    min-width: 40%;
    margin-right: 15px;
  }

  .note-wrapper .note-body .bg-note .note-card {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    margin-right: 0px !important;
  }
}

.practice-stepper-for-mobile-and-tablet .practice-select {
  color: #000 !important;
}
