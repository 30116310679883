.upload-progress {
  width: 100%;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.upload-progress-inner {
  width: 0;
  height: 100%;
  background-color: green;
  border-radius: 4px;
}

.checkbox-with-progress {
  margin-bottom: 10px;
}
/* 
.information {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.checkbox-container {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 5px;
}
.checkbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5; 
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 5px;
}

.file-size {
  margin-left: "90px";
}
.bx.bxs-file-pdf {
  font-size: 24px;
  color: #ff5722;
  margin-right: 5px;
} */

/* CSS */
/* CSS */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-gradient-text {
  background-image: linear-gradient(to right, #6dd5fa, #7ee7db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.text-stroke-teal-500 {
  -webkit-text-stroke: 2px #6dd5fa;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 80%; /* Adjust the width as needed */
  max-height: 80%; /* Adjust the height as needed */
  overflow-y: auto; /* Enable scrolling if content exceeds height */
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}


.bg-sky-new-400{
  background: linear-gradient(273.24deg, #40c2d4 0%, #3dcee8 100%);;
 }

/* Additional styles can be added for better appearance and positioning */

/* Additional styles can be added for better appearance and positioning */
