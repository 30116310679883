:root {
  --background-image-url: url("/public/images/Certificates/certificate-desktop.jpg");
}
/* .certificate-app {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: rgb(246, 255, 254);
  display: flex;
} */

/* .certificate-main {
  width: 100%;
  display: flex;
  flex-direction: column;
} */

/* .certificate-content {
  padding: 20px 25px 0px 25px;
  display: flex;
} */

.certificate-left-side-content {
  width: 75%;
  padding-right: 25px;
}

.certificate-right-side-content {
  width: 25%;
}

.certificate-tags {
  border-radius: 30px;
  background: #e0f6f4;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 6px;
  width: fit-content;
}

.certificate-tags div {
  border-radius: 25px;
  width: fit-content;
  background-color: #ffffff;
  height: 100%;
  padding: 8px 14px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.certificate-tags div span {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #595f6e;
}

.certificate-tags .active-tag {
  background: linear-gradient(273.24deg, #40c2d4 0%, #3dcee8 100%);
}

.certificate-tags .active-tag span {
  color: #ffffff;
}

.certificate-box {
  width: 100%;
}

.certificate-card {
  border-radius: 15px;
  height: 330px;
  background-image: url("/public/images/Certificates/certificateNew.png");
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  background-position: center !important;
}

.certificate-disabled {
  background: #dff9f666 !important;
  position: relative;
}

.certificate-open {
  position: relative;
}

.certificate-open .certificate-card {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.certificate-disabled .certificate-disabled-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}

.certificate-disabled .certificate-disabled-footer,
.certificate-open .certificate-open-footer {
  position: absolute;
  bottom: 28px;
  right: 20px;
  z-index: 1;
}

.certificate-disabled .certificate-disabled-footer button,
.certificate-open .certificate-open-footer button {
  background: #35c69d;
  border-radius: 45px;
  width: 180px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Outfit;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.certificate-disabled .certificate-disabled-footer button img {
  padding-right: 10px;
}

.certificate-disabled .certificate-disabled-content img {
  height: 80px;
  width: 80px;
}

.certificate-disabled .certificate-disabled-content span {
  font-family: Outfit;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #3c4250;
  width: 215px;
  margin-top: 10px;
}

.certificate-disabled .certificate-card {
  opacity: 0.8;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.certificate-right-side-content .calendar-content {
  margin: 0px;
}

/* .certificate-right-side-content .semicircle-content {
  margin: 20px 0px 20px 0px;
} */

.report-template-pdf {
  position: absolute;
  width: 10%;
  overflow: scroll;
  height: 1px;
}

@media only screen and (max-width: 480px) {
  .student-content,
  .certificate-tags {
    width: 100%;
    flex-direction: column;
  }

  .certificate-left-side-content,
  .certificate-right-side-content {
    width: 100%;
    padding: 0px;
  }

  .certificate-tags {
    height: auto;
  }

  .certificate-tags > div {
    width: 100%;
    margin-bottom: 8px;
  }

  .certificate-box .MuiGrid-container .MuiGrid-item {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0px 0px 24px 0px;
  }

  .certificate-card {
    height: calc(100vw - 33vw);
    background-position: 100% 100% !important;
    background-image: url("/public/images/Certificates/certificateNew.png");
    /* maang-certificate-template-mobile.jpg */
  }

  .certificate-disabled .certificate-disabled-content img {
    width: 40px;
    height: auto;
  }

  .certificate-disabled .certificate-disabled-content span {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
  }

  .certificate-disabled .certificate-disabled-content {
    transform: translate(-50%, -80%);
  }

  .certificate-disabled-footer {
    display: flex;
    align-content: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, -80%);
  }

  .certificate-right-side-content {
    margin-bottom: 20px;
  }

  .certificate-right-side-content .calendar-content {
    margin: 30px 0px 30px 0px;
  }

  .student-content .MuiGrid-container {
    margin: 0px;
    width: 100%;
  }

  .report-template-pdf {
    position: absolute;
    width: calc(100% - 30px);
    overflow: scroll;
  }

  .certificate-open .certificate-open-footer {
    right: 32px;
  }
}

/* @media only screen and (min-width: 821px) and (max-width: 1024px) {
    .certificate-card {
      height: 20px;
    }
  } */
@media only screen and (min-width: 481px) and (max-width: 820px) {
  .student-content {
    width: 100%;
    flex-direction: column;
  }

  .certificate-left-side-content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .certificate-card {
    height: calc(100vw - 33vw);
    background-position: 100% 100% !important;
    /* background-image:  url() */
  }

  .certificate-open .certificate-open-footer {
    bottom: 35px;
    right: 55px;
  }

  .certificate-left-side-content,
  .certificate-right-side-content {
    width: 100%;
  }

  .certificate-right-side-content {
    display: flex;
    flex-direction: row;
    margin: 30px 0px 20px 0px;
    padding: 0px;
  }

  .certificate-right-side-content .calendar-content {
    width: 40%;
    margin: 0px;
    padding: 10px;
  }

  .certificate-right-side-content .semicircle-content {
    width: 60%;
    margin: 0px 0px 0px 10px;
    padding: 10px;
  }

  .certificate-box .MuiGrid-container .MuiGrid-item {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1200px) {
  .student-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .report-template-pdf {
    position: absolute;
    width: 10%;
    overflow: scroll;
  }
  .certificate-left-side-content {
    width: 100%;
    padding-right: 25px;
  }

  .certificate-right-side-content {
    display: flex;
    width: 100%;
  }
  .certificate-right-side-content .calendar-content {
    margin: 0px 10px;
    padding: 10px;
    width: calc(45%-20px);
  }
  .semicircle-content {
    width: 55%;
  }
  .student-main {
    width: 100%;
  }
  .certificate-box {
    width: 100%;
  }
  .certificate-card {
    height: 280px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 700px) {
  .certificate-left-side-content {
    padding: 0px;
  }

  .certificate-right-side-content {
    flex-direction: column;
  }

  .certificate-right-side-content .semicircle-map {
    flex-direction: column;
    align-items: center;
  }

  .certificate-right-side-content {
    margin-top: 50px;
  }

  .certificate-right-side-content .semicircle-content {
    margin-top: 20px;
  }

  /* .certificate-card {
    height: 47vh;
  } */
}
