.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: hsl(0deg 0% 0% / 43%) !important;
}

.modal-open {
  display: inline-flex;
  width: 100%;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 410px;
}
.curse_main_price_modal .modal-content {
  max-width: 600px;
}

.modal-header {
  margin-bottom: 20px;
}

.modal-body {
  margin-bottom: 20px;
}
