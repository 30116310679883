@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Parisienne&family=UnifrakturCook:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Parisienne&family=UnifrakturCook:wght@700&display=swap');

@font-face {
    font-family: 'Zinaida';
    src: url('fonts/Zinaida.woff2') format('woff2'),
        url('fonts/Zinaida.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

;

@font-face {
    font-family: 'the_seasonsregular';
    src: url('fonts/fontspring-demo-theseasons-reg-webfont.woff2') format('woff2'),
        url('fonts/fontspring-demo-theseasons-reg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

;

/* @font-face {
    font-family: 'the_seasonsbold';
    src: url('fonts/fontspring-demo-theseasons-bd-webfont.woff2') format('woff2'),
        url('fonts/fontspring-demo-theseasons-bd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}; */


body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: Arial, sans-serif;
}

.main_bg_start5 {
    background-color: #f5f5f1;
    max-width: 1102px;
    width: 100%;
    height: 816px;
    /* height: 100vh; */
    padding: 20px;
    padding-bottom: 10px;
}

.certificate_container_bg5 {
    width: 100%;
    height: 100%;
    background-image: url(./assets/Alpha-Certificate-v3.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    display: flex;
    align-items: flex-start; /* Align items at the top */
    justify-content: flex-start;
    flex-direction: column;
}

table {
    width: 70%;
    border-collapse: collapse;
    padding: 20px;
}

td {
    /* padding: 20px; */
    text-align: center;
}

.title {
    font-size: 24px;
    font-weight: bold;
}

.subtitle {
    font-size: 18px;
}

.content {
    font-size: 20px;
}

.footer {
    font-size: 16px;
}