@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.quiz-week-body {
  /*width: 100%;
  display: flex;*/
}

.quiz-week-left {
  /* width: 75%; */
  /* width: calc(100% - 295px);
  margin-right: 25px;
  box-shadow: 0px 5px 25px 0px #3e909c1a;
  background: #ffffff;
  border-radius: 15px; */
}

.quiz-week-right {
  /* width: 25%; */
  /* width: 295px; */
}

.quiz-header {
  /* width: 100%;
  display: flex;
  justify-content: center; */
  padding-top: 30px;
}

.quiz-question {
  /*height: 300px;
  width: 85%;
  background-repeat: no-repeat !important;
  background-size: contain !important;*/
}

.quiz-question .question {
  /* display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; */
}

.quiz-question .question .number {
  height: 40px;
  min-width: 40px;
  width: 40px;
  border-radius: 50%;
  background: #3c42501a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz-question .question .number span {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #3c4250;
}

.quiz-question .question .text {
  /* padding-left: 10px; */
  font-family: Outfit;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c4250;
}

.question-wrapper {
  /* width: 85%;
  display: flex; */
}

.divider {
  display: flex;
  align-items: center;
}

.divider span {
  /* font-family: Outfit;
  font-size: 25px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #595f6e; */
}

.divider::before {
  background: linear-gradient(270deg, #3fc8de 50%, rgba(62, 201, 223, 0) 100%);
}
.divider::after {
  background: linear-gradient(90deg, #3fc8de 50%, rgba(62, 201, 223, 0) 100%);
}

.divider::before,
.divider::after {
  flex: 1;
  content: "";
  padding: 1px;
  margin: 5px;
}

.quiz-option-text {
  width: 85%;
  padding: 30px 0px;
  margin: 0 auto;
}

.quiz-options-box {
  /* width: 100%;
  display: flex;
  justify-content: center !important; */
}
.quiz-options {
  /* width: 85%; */
}

.quiz-next {
  /* border-top: 1px solid#C5D9DC;
  padding: 20px 0px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  padding-right: 14px; */
}

.quiz-next button {
  /* background: #35c69d;
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px 20px; */
}
.quiz-next button img {
  padding-left: 4px;
}

.quiz-next button span {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.quiz-point-table {
  /* background: #ffffff;
  box-shadow: 0px 5px 25px 0px #3e909c1a;
  border-radius: 10px;
  padding: 14px; */
}

.quiz-point-table .box {
  padding-top: 20px;
}

.quiz-point-table .title {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c4250;
  margin-bottom: 10px;
}

.quiz-point-table .quiz-number {
  /* border-radius: 10px;
  width: 100%;
  height: 60px; */
  /* background: #E0F6F4; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.css-a5rdam-MuiGrid-root {
  margin: 0 !important;
  padding: 0;
  width: 100% !important;
}

.quiz-point-table .quiz-number span {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  /* color: #595F6E; */
}

.quiz-not-attempted {
  background: #dcdde0;
}

.quiz-point-table .quiz-not-attempted span {
  color: #595f6e;
}

.quiz-point-table .quiz-ongoing span {
  color: #ffffff;
}

.quiz-ongoing {
  background: #35c69d;
}

.quiz-point-table .quiz-attempted span {
  color: #595f6e;
}

.quiz-attempted {
  background: #e0f6f4;
}

.quiz-result-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.quiz-result-body-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0px 20px; */
}

.quiz-result-body-bottom {
  width: 100%;
  /* padding: 0px 20px; */
}

.quiz-result-body-top .tags {
  background: #e0f6f4;
  height: 50px;
  border-radius: 30px;
  width: 310px;
  padding: 6px;
  display: flex;
  align-items: center;
}

.quiz-result-body-top .tags .tag {
  background: #ffffff;
  border-radius: 25px;
  padding: 10px 20px;
}
.count-down-mobile {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 15px 0px #3b9aa826;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.count-down-mobile span {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #40c3d6;
  padding-left: 4px;
}
/* start media query */

@media only screen and (max-width: 480px) {
  /* .student-content .quiz-week-body {
    flex-direction: column;
  }
  .quiz-week-body .quiz-week-left {
    width: 100% !important;
  }
  .quiz-week-body .quiz-week-right {
    width: 100% !important;
    margin: 20px 0px;
  }

  .student-content
    .quiz-week-body
    .quiz-week-right
    .MuiGrid-container
    .MuiGrid-item {
    width: 25% !important;
    flex-basis: 25% !important;
  } */

  .quiz-week-left .quiz-options-box .quiz-options {
    flex-direction: column;
    width: 100%;
  }

  .quiz-question .question {
    height: auto;
  }

  .quiz-question .question .text,
  .quiz-question .question .number span {
    font-size: 18px;
  }

  .question-wrapper {
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .quiz-question {
    /* width: 100%;
    background: url("/public/images/Quiz/bg-quiz-mobile.svg") !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center; */
  }

  .quiz-option-text {
    width: 100%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
  .count-down-row > .count-down-mobile {
    display: block;
  }
}

.count-down-row > .count-down-mobile {
  display: flex;
  width: max-content;
  margin: 0;
}

@media (min-width: 1025px) {
  .count-down-row {
    display: none;
  }
}

@media (max-width: 1025px) {
  .quiz-week-block {
    display: flex;
    flex-direction: column;
  }
  /* .quiz-week-block > div:first-child{
  order: 2;
} */
  .quiz-week-block > div:last-child {
    /* order: 1; */
    margin-top: 15px;
  }
  .exit-button-block {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 820px) {
  .count-down-mobile {
    display: none;
  }
  .student-content .quiz-week-body {
    flex-direction: column;
  }
  .quiz-week-body .quiz-week-left {
    width: 100% !important;
  }
  .quiz-week-body .quiz-week-right {
    width: 100% !important;
    margin: 20px 0px;
  }

  .quiz-week-left .quiz-options-box .quiz-options {
    width: 100%;
  }

  .quiz-week-left
    .quiz-options-box
    .quiz-options
    .MuiGrid-container
    .MuiGrid-item {
    padding: 20px 0px 0px 0px;
  }

  .quiz-week-left
    .quiz-options-box
    .quiz-options
    .MuiGrid-container
    .MuiGrid-item
    button {
    width: 100%;
  }

  .quiz-point-table .box {
    display: flex;
  }

  .quiz-question .question .text,
  .quiz-question .question .number span {
    font-size: 20px;
  }

  .quiz-question {
    /*width: 100%;
    background: url("/public/images/Quiz/bg-quiz-tablet.svg") !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;*/
  }
}

.question-body {
  background-image: url(/public/images/Quiz/question-main-bg.png);
  background-repeat: repeat-y;
  background-size: 100%;
  background-position: center top;
}

.question-body:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 240px;
  right: 0;
  background-image: url(/public/images/Quiz/question-top.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
}
.question-body:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 240px;
  background-image: url(/public/images/Quiz/question-bottom.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
  .quiz-week-body .quiz-week-right {
    width: 100% !important;
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 821px) {
  .count-down-mobile {
    display: none;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) and (min-height: 600px) and (max-height: 800px) {
  .quiz-week-left {
    width: 65%; /* Adjust the width as needed */
    margin-right: 5%;
  }

  .quiz-week-right {
    width: 30%; /* Adjust the width as needed */
    margin: 20px 0;
  }

  .quiz-week-left .quiz-options-box .quiz-options {
    width: 100%;
    margin: 0 auto;
  }

  .quiz-week-left
    .quiz-options-box
    .quiz-options
    .MuiGrid-container
    .MuiGrid-item {
    padding: 20px 0 0;
  }

  .quiz-week-left
    .quiz-options-box
    .quiz-options
    .MuiGrid-container
    .MuiGrid-item
    button {
    width: 100%;
  }

  .quiz-point-table .box {
    display: flex;
    width: 80%; /* Adjust the width as needed */
    margin: 0 auto;
  }

  .quiz-question .question .text,
  .quiz-question .question .number span {
    font-size: 20px;
  }

  .quiz-question {
    width: 100%;
    background: url("/public/images/Quiz/bg-quiz-tablet.svg") !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (width: 1024px) and (height: 1366px) {
  .quiz-week-left {
    /* width: 65%;
    margin-right: 5%; */
  }

  .quiz-week-right {
    /* width: 30%; */
    margin: 20px 0;
  }

  .quiz-week-left .quiz-options-box .quiz-options {
    width: 100%;
    margin: 0 auto;
  }

  .quiz-week-left
    .quiz-options-box
    .quiz-options
    .MuiGrid-container
    .MuiGrid-item {
    padding: 20px 0 0;
  }

  .quiz-week-left
    .quiz-options-box
    .quiz-options
    .MuiGrid-container
    .MuiGrid-item
    button {
    width: 100%;
    padding-left: 10px;
  }

  .quiz-point-table .box {
    display: flex;
    /* width: 80%; */
    margin: 0 auto;
  }

  .quiz-question .question .text,
  .quiz-question .question .number span {
    font-size: 20px;
  }

  .quiz-question {
    width: 100%;
    /* background: url("/public/images/Quiz/bg-quiz-tablet.svg") !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center; */
  }
}

@media only screen and (min-width: 640px) and (max-width: 820px) {
  .student-content .MuiGrid-container .MuiGrid-root {
    max-width: 50%;
    flex-basis: 50%;
  }

  .question-number .MuiGrid-container .MuiGrid-root {
    max-width: none !important;
    flex-basis: auto !important;
  }
}

@media (max-width: 639px) {
  .student-content .MuiGrid-container .MuiGrid-root {
    max-width: 100%;
    flex-basis: 100%;
  }
}
