@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");
@import url("https://fonts.googleapis.com/css2?family=UnifrakturCook:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Parisienne&family=UnifrakturCook:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Cinzel+Decorative:wght@400;700;900&family=Parisienne&family=UnifrakturCook:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Cinzel+Decorative:wght@400;700;900&family=Great+Vibes&family=Parisienne&family=UnifrakturCook:wght@700&display=swap");

@font-face {
  font-family: "Zinaida";
  src: url("fonts/Zinaida.woff2") format("woff2"),
    url("fonts/Zinaida.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: Arial, sans-serif;
}

.main_bg_start4 {
  /* background-color: #f3f2ed; */
  max-width: 1104px;
  width: 100%;
  /* height: calc(100vh - 60px); */
  height: 816px;
  margin: auto;
  padding: 0px;
  padding-bottom: 40px;
  padding-top: 20px;
  background-image: url(../ReportTemplate/assets/Internship-Certificate-bg-4.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
}

.certificate_container_bg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 70px;
}

table {
  width: 70%;
  margin: auto;
  border-collapse: collapse;
  padding: 20px;
}

td {
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.subtitle {
  font-size: 50px;
  font-family: "UnifrakturCook", cursive;
  line-height: 1;
  color: #0c253c;
}

.certificate-container .content {
  text-align: center;
  margin: 50px 0;
  font-size: 20px;
}

.certificate-container .content h2 {
  margin: 10px 0;
}

.certificate-container .content p {
  margin: 10px 0;
}
.certificate-container .footer {
  font-size: 16px;
}

.signatures {
  display: flex;
  justify-content: space-around;
}

.signature {
  margin: 0 100px;
  text-align: center;
}

.signature img {
  width: 140px;
  margin-bottom: 4px;
}

.signature p {
  margin-top: 4px;
}

.seal {
  display: inline-block;
  width: 70px;
  height: 70px;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
  font-size: 24px;
  margin: 0 auto;
}
