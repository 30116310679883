.email-modal .message-box,
.chat-row {
  height: 400px;
  width: 350px;
  border-radius: 10px;
}
.email-modal .message-box .message-header {
  background: #43c3d4;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
}

.email-modal .message-header .message-text {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  width: 90%;
}
.email-modal .message-header .close-sign {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.message-row {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid #a4c3c8;
  cursor: pointer;
}

.email-modal .message-row .message-images {
  width: 50px;
  height: 50px;
}

.email-modal .message-row .message-title {
  color: #595f6e;
  font-family: Outfit;
  font-size: 25px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 10px;
}
.email-modal .MuiDialog-scrollPaper .MuiPaper-elevation {
  border-radius: 10px;
  position: absolute;
  right: calc(25% - 12px);
  bottom: 30px;
  margin: 0px;
}

.chat-header {
  background-color: #43c3d4;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  justify-content: space-between;
}
.chat-title {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.chat-row .chat-left-side {
  display: flex;
  align-items: center;
}
.chat-body {
  height: calc(100% - 45px);
  padding: 14px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.chat-button {
  width: 100%;
  background: #40c2d4;
  border-radius: 7px;
  height: 44px;
  color: #ffffff;
  font-family: Outfit;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.chat-text-message {
  margin-bottom: 10px;
  background: #118ede;
  max-width: 200px;
  border-radius: 15px;
  color: #ffffff;
  font-family: Outfit;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  padding: 10px;
}
.chat-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.email-modal {
  background: none !important;
}

.email-modal .MuiModal-backdrop {
  position: unset;
  background: none;
  opacity: 1;
}

@media only screen and (max-width: 480px) {
  .email-modal .message-box,
  .chat-row {
    width: calc(100vw - 50px) !important;
  }

  .email-modal .MuiDialog-scrollPaper .MuiPaper-elevation {
    position: relative;
    right: auto;
    bottom: auto;
  }

}

@media only screen and (min-width: 481px) and (max-width: 820px) {
  .email-modal .MuiDialog-scrollPaper .MuiPaper-elevation {
    position: relative;
    right: auto;
    bottom: auto;
  }
}
