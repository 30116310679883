.app-logo,
.menu-icon {
  display: none;
}

.right-header .user-name {
  padding-left: 17px;
}

.left-header {
  display: flex;
  align-items: center;
}

.left-header img {
  padding-right: 10px;
}

.count-down {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 15px 0px #3b9aa826;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}

.count-down span {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #40c3d6;
  padding-left: 4px;
}

.drawer-sidebar-menu .active-menu {
  box-shadow: 0px 10px 15px 0px #36c0d440;
  background: linear-gradient(273.24deg, #40c2d4 0%, #3dcee8 100%);
  border-radius: 10px;
}

.drawer-sidebar-menu .active-menu span {
  color: #ffffff;
}

.app-header header {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

@media only screen and (max-width: 820px) {
  body .left-header {
    display: none;
  }
  body .app-logo {
    height: 30px;
    width: 80px;
    display: block;
  }

  .app-header .MuiPaper-root {
    margin: 0px;
    /* padding: 15px; */
    height: auto;
  }

  .right-header .MuiPaper-root,
  .right-header .user_icon,
  .right-header .user-name,
  .right-header .down-icon {
    display: none;
  }

  .right-header .search-icon,
  .right-header .menu-icon {
    display: block;
  }

  .drawer-bar .MuiDrawer-paper {
    height: 100vh;
  }
}

@media only screen and (min-width: 481px) and (max-width: 820px) {
  .drawer-bar .MuiDrawer-paper {
    height: 100vh;
  }

  .right-header .search-icon,
  .right-header .right-header {
    display: none;
  }

  .right-header .MuiPaper-root {
    display: flex;
    height: 34px;
  }
}

@media only screen and (min-width: 820px) {
  .right-header .search-icon {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .app-header .count-down {
    display: none;
  }
}

.count-down + .search-header {
  margin-left: 0;
}
