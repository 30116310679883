.student-app {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: rgb(246, 255, 254);
    display: flex;
    flex-wrap: wrap;
  }
  
  .student-main {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .student-content {
    /* padding: 20px 25px 0px 25px;
    display: flex; */
  }
  
  @media only screen and (max-width: 480px) {
    .student-app .student-main .student-content {
      /* padding: 15px; */
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 820px) {
    .student-app .student-main .student-content {
      /* padding: 15px; */
    }
  }
  
  @media(min-width:821px) {
    .student-app .sidebar {
        flex: 0 0 auto;
        width: 248px;
    }
  
    .student-app .student-main {
      flex: 0 0 auto;
      width: calc(100% - 248px);
    }
  
    .student-app .sidebar-short + .student-main {
      width: calc(100% - 85px);
    }
  }