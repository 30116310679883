.whiteboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.whiteboard-canvas {
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: crosshair;
  touch-action: none; /* Disable touch interactions */
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Set wrapper height to fill viewport */
  position: relative;
}

.header {
  background-color: white;
  color: white;
  padding: 10px;
}

.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#title {
  font-size: 24px;
  position: fixed;
  top: 8px;
  z-index: 100000;
  left: 68px;
}

.excalidraw-container {
  flex-grow: 1; /* Grow to fill remaining space */
}

.lg-button {
  color: #2a2a2a;
  font-size: 1.2em;
  background: whitesmoke;
  border: rgb(207, 207, 207) 1px solid;
  border-radius: 4px;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.lg-button:hover {
  background: #dddddd;
}

/* @media screen and (max-width: 775px) {
  .header {
    height: 100vh;
    width: 100px;
    padding: 10px;
    left: 0;
  }
  .tools {
    flex-direction: column;
    margin: 0;
    left: 0;
    margin: 0;
    top: 0px;
    width: 40px;
    height: 200px;
    justify-content: flex-start;
  }
} */
